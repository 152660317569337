<!--
 * @Description: 客户信息日志
 * @Author: LiangYiNing
 * @Date: 2022-01-21 10:32:44
 * @LastEditTime: 2022-07-15 15:43:42
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="corpId">
              <el-input
                v-model.trim="searchForm.corpId"
                @input="corpIdChange"
                placeholder="客户ID"
                title="客户ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpName">
              <el-input
                v-model.trim="searchForm.corpName"
                @input="corpNameChange"
                placeholder="客户名称"
                title="客户名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="billingCorpName">
              <el-input
                v-model.trim="searchForm.billingCorpName"
                placeholder="开票名称"
                title="开票名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="operationTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="操作时间"
                ref="effectiveDatePicker"
                v-model="searchForm.operationTime"
                title="操作时间"
                :picker-options="pickerOptions('searchForm.operationTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="操作时间（始）"
                end-placeholder="操作时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <el-button
            type="primary"
            @click="handleContrast"
            style="margin-right:10px"
            >对比信息</el-button
          >
          <i class="e6-icon-export_fill" title="导出" @click="exportData"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          @selection-change="handleSelectionChange"
          :header-cell-class-name="cellClass"
          class="table"
        >
          <el-table-column
            type="selection"
            width="50"
            :selectable="checkSelectable"
          ></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'attrName'">
                <el-button type="text" @click="toDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column> -->
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!--   -->
    <Contrast
      :dialogVisible="dialogVisible"
      :data="contrastData"
      :columnTitle="columnTitle"
      :rowData="rowData"
      @handleClose="handleClose"
    >
    </Contrast>
  </div>
</template>

<script>
import { printError } from "@/utils/util";
import { getCorpHis, getCorpHisExport } from "@/api";
import record from "../mixins";
const nowTime = new Date().getTime(); // 打开页面时的时间戳
import { exportData } from "@/utils/download";

export default {
  name: "customerRecord",
  data() {
    return {
      searchForm: {
        corpId: "",
        corpName: "",
        billingCorpName: "",

        operationTime: [nowTime - 2592000000, nowTime], //创建时间
        startTime: nowTime - 2592000000, //创建开始时间
        endTime: nowTime, //创建结束时间
        pageIndex: 1,
        pageSize: 20
      },
      dataIdFixedName: "hisId",
      queryListAPI: getCorpHis, //获取列表的请求名
      // getContrastInfo: getContrastInfo,
      headerType: 4 //客户信息的表头
    };
  },
  mixins: [record],
  watch: {
    // 创建开始时间
    "searchForm.operationTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.operationTime = this.searchForm.operationTime.reverse();
        }
        this.searchForm.startTime = val[0] || "";
        this.searchForm.endTime = val[1] || "";
      }
    }
  },
  computed: {},
  components: {},
  methods: {
    //获取设备列表
    async queryList() {
      const {
        startTime,
        endTime,
        corpId,
        corpName,
        billingCorpName
      } = this.searchForm;
      let queryFlag = startTime && endTime;
      queryFlag = queryFlag || corpId || corpName || billingCorpName;
      if (!queryFlag) {
        this.$message({
          type: "warning",
          duration: 4000,
          message:
            "客户ID、客户名称、开票名称、操作时间必须选择其中之一作为查询条件！"
        });
        return;
      }
      try {
        this.loading = true;
        let res = await this.queryListAPI(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.elementTableDoLayout();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    corpIdChange(val) {
      if (val) {
        this.searchForm.corpName = "";
      }
    },
    corpNameChange(val) {
      if (val) {
        this.searchForm.corpId = "";
      }
    },
    //  导出
    exportData() {
      exportData(this, getCorpHisExport);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.table {
  .DisableSelection {
    .cell {
      display: none !important;
    }
  }
}
</style>
